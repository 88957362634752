<template>
  <div>
    <el-button
      style="color: #ffffff; background-color: #ff7e00; margin-bottom: 20px"
      @click="add"
      >新增角色</el-button
    >

    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark">
      <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="short_name" label="名称"></el-table-column>
      <el-table-column prop="create_time" label="添加时间"></el-table-column>
     <el-table-column prop="type" label="类型">
       </el-table-column>
      <el-table-column prop="state" label="状态">
         <template slot-scope="{ row }">
         <el-tag type="success" v-if="row.state === 1">正常</el-tag>
<el-tag type="warning" v-if="row.state === 0">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column  label="操作">
        <template slot-scope="{ row }">
          <samp style="color: #ff7e00; margin-right: 10px" @click="update(row)"
            >编辑菜单</samp
          >
          <samp style="color: #ff7e00; margin-right: 10px" @click="openPermission(row)"
            >编辑权限</samp
          >
          <samp style="color: #f56c6c" @click="delrole(row)">删除</samp>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <paging-fy
      @currentPageChange="currentPageChange"
      :currentPage="currentPage"
      :total="total"
    ></paging-fy>
    <!--新增  -->
    <!--新增  -->
    <el-dialog :visible.sync="dialogVisible" width="40%" title="新增">
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
        >
          <el-form-item label="角色名称" prop="type" required>
            <el-input
              v-model="ruleForm.type"
              placeholder="请输入角色名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色简称" prop="short_name" required>
            <el-input
              v-model="ruleForm.short_name"
              placeholder="请输入角色简称"
            ></el-input>
          </el-form-item>
          <el-form-item label="管理员级别" required>
            <el-input
              v-model="ruleForm.levels"
              placeholder="管理员级别"
            ></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort" >
            <el-input
              v-model="ruleForm.sort"
              placeholder="排序，越小越靠前"
            ></el-input>
          </el-form-item>
          <el-form-item label="后台类型" prop="routeUrl">
            <el-select :value="type" placeholder="请选择" @change="typeChange">
              <el-option
                v-for="(item,i) in typeData"
                :key="i"
                :label="item"
                :value="item"
                @change="change"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="菜单权限" required>
            <el-tree
              :props="defaultProps"
              :data="data"
              show-checkbox
              node-key="id"
              :default-checked-keys="userCheck"
              @check="check"
              ref="tree"
              @node-click="handleNodeClick">
            </el-tree>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          style="background-color: #ff7e00; color: #ffffff"
          @click="onsubmit"
          >{{ editWay == "add" ? "添加" : "修改" }}</el-button
        >
      </span>
    </el-dialog>
<!-- 权限 -->
<el-dialog :visible.sync="perDialogVisible" width="40%" title="新增">
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
        >
        <el-form-item label="角色权限">
          <el-tag type="info" closable v-for="tag in userPermission" :key="tag.id" class="tag" @close="remove(tag)">{{tag.descr}}</el-tag>
        </el-form-item>
        <el-form-item label="添加权限"  >
            <el-cascader ref="cascader"
             v-model="checkPermissionList" 
             :props="PermissionProps"  
             :options="parentMenu" 
             :show-all-levels="false" 
             @remove-tag="removeTag"
             @change="permChange"></el-cascader>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="perDialogVisible = false">取 消</el-button>
        <el-button
          style="background-color: #ff7e00; color: #ffffff"
          @click="submitPermission"
          >修改</el-button
        >
      </span>
    </el-dialog>

<!-- 删除 -->
    <el-dialog
  title="提示"
  :visible.sync="delDialogVisible"
  width="30%"
  center>
  <span>确定要删除这个角色，删除之后不可恢复。</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="delDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="del">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      editWay: "add",
      currentPage: 1,
      total: 0,
      dialogVisible: false,
      form: {},
      typeData: [],
      type: "haima_houtai",
      ruleForm: {},
      curRoleType: {},
      tableData: [],
      data: [],
      rules: {
        type: [{ required: true, message: "请输入名称", trigger: "blur" }],
        short_name: [
          { required: true, message: "请输入简称", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      defaultProps: {
        children: "childrens",
        label: "name",
      },
      ids: [],
      userCheck: [],
      curRole: {},
      delDialogVisible: false,
      perDialogVisible: false,
      parentMenu: [],
      permissionList: [],
      checkPermissionList: [],
      userPermission: [],
      PermissionProps: {
        label: "descr",
        lazy: true,
        value: 'id',
        children: "childrens",
        multiple: true,
        lazyLoad: async (node, resolve) => {
          if (node.level == 1) {
            let nodes = await this.getMenuPermission(node.value);
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(nodes);
          }
          resolve();
        },
      },
    };
  },
  created() {
    this.getList();
    this.getRoleType();
    this.getMune();
    this.getParentMenu();
  },

  methods: {
    openedit(row){
      this.editWay = "edit";
      this.ruleForm = row;
      this.dialogVisible = true;
    },
    permChange(perm){
      setTimeout(()=>{
        let nodes = this.$refs.cascader.getCheckedNodes(true)
        console.log(nodes);
        for(let i=0;i<nodes.length;i++){
          let node = nodes[i].data
          let flag = false
          for(let j=0;j<this.userPermission.length;j++){
            if(this.userPermission[j].id == node.id){
              flag = true
              break
            }
          }
          if(!flag){
            this.userPermission.push(node)
          }
        }
      },500)
        
    },
    removeTag(v){
      this.userPermission = this.userPermission.filter(item=>item.id != v[1])
    },
    remove(tag){
      this.userPermission = this.userPermission.filter(item=>item.id != tag.id)
    },
    getRolePermission(row) {
      let params = {
        id: row.id,
      };
      this.$axios({
        url: "authorization/apiUrl/queryRoleIdentity",
        method: "get",
        params,
      }).then((res) => {
        this.userPermission = res.data.data || [];
      });
    },
    submitPermission() {
      console.log(this.checkPermissionList);
      let ids = [];
      for (let i = 0; i < this.checkPermissionList.length; i++) {
        this.checkPermissionList[i][1] &&
          ids.push(this.checkPermissionList[i][1]);
      }
      let data = {
        id: this.ruleForm.id,
        permIds: this.userPermission.map(item=>{return item.id}),
        // menuIds: [384],
      };
      this.$axios({
        url: "authorization/managerIdentity/insert",
        method: "post",
        data,
      }).then((res) => {
        this.getList();
        this.perDialogVisible = false;
        this.$message({ message: res.data.message });
      });
    },
    getMenuPermission(menu_id) {
      return new Promise((resolve, reject) => {
        let params = {
          currentPage: this.currentPage,
          pageSize: 10,
          types: 3,
          status: 2,
          menu_id,
        };
        this.$axios({
          url: "authorization/apiUrl/queryApiTree",
          method: "get",
          params,
        }).then((res) => {
          this.permissionList = res.data.data;
          res.data.data.forEach((item) => {
            item.leaf = true;
          });
          resolve(res.data.data);
        });
      });
    },
    getParentMenu() {
      let params = {
        currentPage: this.currentPage,
        pageSize: 30,
        type: "haima_houtai",
        parent_id: 0,
         status:2
      };
      this.$axios({
        url: "authorization/menu/queryManagerListPage",
        method: "get",
        params,
      }).then((res) => {
        this.parentMenu = res.data.data.rows;
      });
    },
    openPermission(row) {
      this.getRolePermission(row);
      this.ruleForm = row;
      this.perDialogVisible = true;
    },
    //菜单列表
    getMune() {
      let params = {
        type: this.type,
      };
      this.$axios({
        url: "authorization/menu/queryMenuList",
        method: "get",
        params,
      }).then((res) => {
        this.data = res.data.data;
      });
    },
    getList() {
      let params = {
        currentPage: this.currentPage,
        pageSize: 10,
        state: 1,
      };
      this.$axios({
        url: "authorization/managerIdentity/queryRoleList",
        method: "get",
        params,
      }).then((res) => {
        this.tableData = res.data.data.rows;
        this.total = res.data.data.total;
      });
    },
    //角色类型
    getRoleType() {
      this.$axios({
        url: "authorization/menu/queryMenuType",
        method: "get",
      }).then((res) => {
        this.typeData = res.data.data;
      });
    },
    update(row) {
      this.editWay = "edit";
      this.dialogVisible = true;
      this.ruleForm = row;
      this.getCurMenu();
      this.getRoleMenu(row.id);
    },
    delrole(row) {
      this.delDialogVisible = true;
      this.ruleForm = row;
    },
    change() {
      this.getCurMenu();
      this.getRoleMenu(this.ruleForm.id);
    },
    getCurMenu() {
      this.$axios({
        url: "authorization/menu/queryMenuList",
        method: "get",
        params: {
          type: this.type,
        },
      }).then((res) => {
        this.data = res.data.data;
      });
    },
    del() {
      this.$axios({
        url: "authorization/menu/queryMenuListByIdentityId",
        method: "get",
        params: {
          id: this.ruleForm.id,
          state: 0,
        },
      }).then((res) => {
        this.getList();
        this.delDialogVisible = false;
        this.$message({ message: res.data.message });
      });
    },
    add() {
      this.editWay = 'add'
      this.dialogVisible = true;
      this.userCheck = [];
      this.ids = [];
    },
    getRoleMenu(id) {
      this.$axios({
        url: "authorization/menu/queryMenuListByIdentityId",
        method: "get",
        params: {
          id,
          type: this.type,
        },
      }).then((res) => {
        let data = res.data.data || [];
        console.log(data);
        let arr = [];
        data.forEach((item) => {
          // arr.push(item.id);
          if (item.childrens) {
            item.childrens.forEach((it) => {
              arr.push(it.id);
            });
          }
        });
        this.userCheck = arr;
        this.ids = arr;
      });
    },
    onsubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.editWay == "add") {
            this.addRole();
          } else {
            this.updateRole();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addRole() {
      let data = {
        short_name: this.ruleForm.short_name,
        type: this.ruleForm.type,
        serial_number: 0,
        sort: this.ruleForm.sort,
        admin_id: 0,
        menuIds: this.ids,
        levels:this.ruleForm.levels
      };
      this.$axios({
        url: "authorization/managerIdentity/insert",
        method: "post",
        data,
      }).then((res) => {
        this.getList();
        this.dialogVisible = false;
        this.$message({ message: res.data.message });
      });
    },
    updateRole() {
      let data = {
        id: this.ruleForm.id,
        short_name: this.ruleForm.short_name,
        type: this.ruleForm.type,
        serial_number: 0,
        sort: this.ruleForm.sort,
        admin_id: 0,
        menuIds: this.ids,
        // menuIds: [384],
      };
      this.$axios({
        url: "authorization/managerIdentity/insert",
        method: "post",
        data,
      }).then((res) => {
        this.getList();
        this.dialogVisible = false;
        this.$message({ message: res.data.message });
      });
    },

    typeChange(v) {
      this.type = v;
      this.getMune();
    },
    currentPageChange(v) {
      this.currentPage = v;
      this.getList();
    },
    handleChanges(data, checked, indeterminate) {
      if (checked) {
        console.log(data);
      }
    },
    handleNodeClick() {},
    check(a, b) {
      let res = this.$refs.tree
        .getCheckedKeys()
        .concat(this.$refs.tree.getHalfCheckedKeys());
      this.ids = res;
    },
  },
};
</script>
  
  <style lang="less">
#PermissionSettings {
  background-color: #ffffff;
  padding: 0 20px;
}

.cell {
  text-align: center;
}
.tag {
  margin-right: 10px;
}
</style>
  