<template>
  <div id="PermissionSettings">
    <content-title :nav="nav"></content-title>
    <div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="账号列表" name="first">
          <admin-manage></admin-manage>
        </el-tab-pane>
         <el-tab-pane label="角色管理" name="second">
          <role-manage></role-manage>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>

import roleManage from "./components/roleManage.vue";
import adminManage from "./components/adminManage.vue";
export default {
  components: {
    
    roleManage,
    adminManage
  },
  data() {
    return {
      dialogVisible: false,
      nav: { firstNav: "权限管理中心", secondNav: "权限设置" },
      activeName: "first",
    }
  }
}
</script>

<style lang="less">
#PermissionSettings {
  background-color: #ffffff;
  padding: 0 20px;
}

.cell {
  text-align: center;
}
</style>
