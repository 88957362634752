<template>
  <div id="">
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-button
          style="color: #ffffff; background-color: #ff7e00; margin-bottom: 20px"
          @click="add"
          >新增账号</el-button
        >
      </el-form-item>
      <el-form-item label="登录账号">
        <el-input v-model="phone" placeholder="输入搜索账号"></el-input>
      </el-form-item>
      <!-- <el-form-item label="绑定用户">
            <el-select
              v-model="user_id"
              placeholder="请选择"
              value-key="user_id"
              filterable
              remote
              clearable
              :remote-method="getUser"
            >
              <el-option
                v-for="item in userList"
                :key="item.user_id"
                :label="item.nick_name"
                :value="item.user_id"
              >
              </el-option>
            </el-select>
         
          </el-form-item> -->
          <el-button
          style="color: #ffffff; background-color: #ff7e00; margin-bottom: 20px"
          @click="search"
          >搜索</el-button>
    </el-form>
    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark">
      <el-table-column prop="login_name" label="账号"> </el-table-column>
      <el-table-column prop="name" label="姓名"> </el-table-column>
      <el-table-column prop="type" label="角色类型">
          <template slot-scope="{row}">
           {{row.short_name || row.type}}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="添加时间"></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="{row}">
            <div v-if="row.status==1">不可用</div>
            <div v-if="row.status==2">可用</div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="操作">
        <template slot-scope="{ row }">
          <samp style="color: #ff7e00; margin-right: 10px" @click="update(row)"
            >修改账号</samp
          >
          <samp style="color: #f56c6c;margin-right:10px;" @click="resetPwd(row)">重置密码</samp>
          <samp v-if="row.status == 2" style="color: #f56c6c" @click="updateStatus(row,1)">禁用</samp>
          <samp v-if="row.status == 1" style="color: #5ebc00" @click="updateStatus(row,2)">解禁</samp>
        </template>
      </el-table-column>
    </el-table>

    <paging-fy
      @currentPageChange="currentPageChange"
      :currentPage="currentPage"
      :total="total"
    ></paging-fy>
    <!--新增  -->
    <el-dialog :visible.sync="dialogVisible" width="40%" title="新增">
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
        >
          <el-form-item label="姓名" prop="name" required>
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="账号" prop="login_name" required v-if="editWay=='add'">
            <el-input
              v-model="ruleForm.login_name"
              placeholder="请输入账号"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="pwd" required v-if="editWay=='add'">
            <el-input
              v-model="ruleForm.pwd"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份角色"  required>
            <el-select
              :value="ruleForm.type"
              placeholder="请选择"
            >
              <el-option
                v-for="item in typeData"
                :key="item.id"
                :label="item.short_name"
                :value="item.type"
                @click.native="typeChange(item)"
              >
              </el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="是否为员工"  required>
            <el-switch
            v-model="ruleForm.is_employees"
            :active-value="1"
            :inactive-value="0"
            active-text="员工"
            inactive-text="非员工">
          </el-switch>
          </el-form-item>
          <el-form-item label="城市">
            <el-cascader
              v-model="cityList"
              :options="cityoptions"
              :props="cityProps"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="绑定用户">
            <el-select
              v-model="ruleForm.user_id"
              placeholder="请选择"
              value-key="user_id"
              filterable
              style="margin-top: 10px"
              remote
              clearable
              :remote-method="getUser"
            >
              <el-option
                v-for="item in userList"
                :key="item.user_id"
                :label="item.nick_name"
                :value="item.user_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="机构">
            <el-select
              filterable
              v-model="ruleForm.mechanism_id"
              clearable
              remote
              :remote-method="searchOrgName"
            >
              <el-option
                v-for="(option, index) in mechanism_name_list"
                :key="index"
                :value="option.id"
                :label="option.mechanism_name"
              ></el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="合作商">
              <cooperatorSelect ref="coop" :coopid="coopid"></cooperatorSelect>
           </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          style="background-color: #ff7e00; color: #ffffff"
          @click="onsubmit"
          >{{ editWay == "add" ? "添加" : "修改" }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 分页 -->
  </div>
</template>
  
  <script>
import cooperatorSelect from '../../../components/select/cooperatorSelect.vue';

export default {
  components:{
    cooperatorSelect
  },
  data() {
    return {
      editWay: "add",
      currentPage: 1,
      total: 0,
      dialogVisible: false,
      form: {},
      tableData: [],
      typeData: [],
      ruleForm: {},
      curRoleType: {},
      options: [],
      userList: [],
      mechanism_name_list: [],
      selected_user: {},
      mechanism_id: "",
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: ["blur","change"] }],
        login_name: [{ required: true, message: "请输入账号", trigger: ["blur","change"] }],
        pwd: [{ required: true, message: "请输入密码", trigger: ["blur","change"] }]
      },
      phone: "",
      cityoptions: [],
      cityProps: {
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityList: [],
      user_id:'',
      coopid:0
    };
  },
  created() {
    this.getList();
    this.getRoleType();
    this.cityoptions = JSON.parse(localStorage.getItem("addrList"));
  },
  methods: {
    add() {
      this.dialogVisible = true;
      this.editWay = "add";
      this.ruleForm = {};
    },
    getList() {
      let params = {
        currentPage: this.currentPage,
        pageSize: 10,
        state: 1,
      };
      this.$axios({
        // url: "authorization/managerUser/queryRoleList",
        url: "authorization/managerUser/queryRoleList",
        method: "get",
        params,
      }).then((res) => {
        this.tableData = res.data.data.rows;
        this.total = res.data.data.total;
      });
    },
    resetPwd(row){
        this.$confirm('确认重置密码？重置密码为123456789').then(res=>{
          this.$axios({
        url: "authorization/managerUser/updateUserPassword",
        method: "get",
        params:{
          login_pass:123456789,
          id:row.id
        },
      }).then((res) => {
        this.$message("重置成功");
      });
        })
    },
    search() {
      let params = {
        currentPage: this.currentPage,
        pageSize: 10,
        // state: 2,
        login_name: this.phone || null,
        user_id:this.user_id || null
      };
      this.$axios({
        url: "authorization/managerUser/queryManagerListPage",
        method: "get",
        params,
      }).then((res) => {
        this.tableData = res.data.data.rows;
        this.total = res.data.data.total;
      });
    },
    //角色类型
    getRoleType() {
      let params = {
        currentPage: 1,
        pageSize: 100,
        state: 1,
      };
      this.$axios({
        url: "authorization/managerIdentity/queryRoleList",
        method: "get",
        params,
      }).then((res) => {
        this.typeData = res.data.data.rows;
      });
    },
    onsubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.editWay == "add") {
            this.addUser();
          } else {
            this.updateUser();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addUser() {
      if (!this.curRoleType.type) {
         this.$message({message:'请选择身份'})
        }
      let data = {
        name: this.ruleForm.name,
        login_name: this.ruleForm.login_name,
        pwd: this.ruleForm.pwd,
        type: this.curRoleType.type,
        admin_id: JSON.parse(localStorage.getItem("managerInfo")).id,
        province: this.cityList[0],
        city: this.cityList[1],
        identity_id: this.curRoleType.id,
        short_name:this.curRoleType.short_name,
        levels: this.curRoleType.levels,
        user_id: this.ruleForm.user_id || 0,
        mechanism_id: this.ruleForm.mechanism_id || 0,
        is_employees: this.ruleForm.is_employees || 0,
        cooperator_id:this.coopid || ''
      };
      this.$axios({
        url: "authorization/managerUser/insert",
        method: "post",
        data,
      }).then((res) => {
        this.$refs.coop.clear()
        // this.data = res.data.data;
        if(res.data.code == 0){
          this.dialogVisible = false;
        this.$message({ message: res.data.message });
        this.getList();
        }else{
          this.$message({ message: res.data.message });
        }
        
      });
    },
    updateStatus(row,s) {
      this.$axios({
        url: "authorization/managerUser/update",
        method: "post",
        data:{
          status:s,
          id:row.id
        },
      }).then((res) => {
        // this.data = res.data.data
        this.$message({ message: res.data.message });
        this.getList();
      });
     
    },
    updateUser() {
      let data = {
        id: this.ruleForm.id,
        name: this.ruleForm.name,
        type: this.curRoleType.type,
        admin_id: this.curRoleType.admin_id,
        province: this.ruleForm.province,
        city: this.ruleForm.city,
        identity_id: this.curRoleType.id,
        levels: this.curRoleType.levels,
        user_id: this.ruleForm.user_id || null,
        mechanism_id: this.ruleForm.mechanism_id || null,
        is_employees: this.ruleForm.is_employees || null,
      };
      this.$axios({
        url: "authorization/managerUser/update",
        method: "post",
        data,
      }).then((res) => {
        // this.data = res.data.data;
        this.dialogVisible = false;
        this.$message({ message: res.data.message });
        this.getList();
      });
    },
    update(row) {
      this.cityList = [row.province, row.city];
      let type = {
        type : row.type,
        admin_id : row.admin_id,
        id : row.identity_id,
        levels : row.levels
      }
      this.curRoleType = type
      this.editWay = "edit";
      this.ruleForm = row;
      this.dialogVisible = true;
    },
    getUser(query) {
      this.user_list = [];
      if (query && typeof query == "string") {
        let url = "/user/user/queryByMessage";
        this.$axios
          .get(url, {
            params: {
              login_name: query,
            },
          })
          .then((res) => {
            this.userList = res.data.data.rows;
          });
      }
    },
    searchOrgName(query) {
      let url = "/user/mastermechanism/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            type: "teach_paypal",
            mechanism_name:
              typeof query == "string" && query != "" ? query : "",
            pageSize: 10,
            currentPage: 1,
            status: 2,
          },
        })
        .then((res) => {
          // console.log(res);
          this.mechanism_name_list = res.data.data.rows;
        })
        .catch((err) => {});
    },
    typeChange(v) {
      this.$set(this.ruleForm,'type',v.type)
      // this.ruleForm.type = v.type
      this.curRoleType = v;
    },
    currentPageChange(v) {
      this.currentPage = v;
      this.getList();
    },
    handleChanges(data, checked, indeterminate) {
      if (checked) {
        console.log(data);
      }
    },
  },
};
</script>
  
  <style lang="less">
#PermissionSettings {
  background-color: #ffffff;
  padding: 0 20px;
}

.cell {
  text-align: center;
}
</style>
  